import React from 'react'
import {Category, scrollToForm} from "../../contact/ContactForm"

const ServiceOutsourcingOfferCta = () => (
  <section className='service-web'>
    <div className='container'>
      <div className='row py-5'>
        <div className='col-xs-12 col-sm-4 col-lg-6'>
          <div className='lead text-left text-sm-right'>
            <div className='lead text-muted'>
              <h1 className='seo-span text-success'>
                Software outsourcing to Poland
              </h1>
              <br />
              will bring your business to the next level
            </div>
            <a
              href='#contact-partial'
              data-form-category='outsourcing'
              className='btn btn-success my-2'
              id='software-outsourcing-more-btn'
              onClick={event => {
                event.preventDefault()
                scrollToForm(Category.FreeConsultation, 0, 500)
              }}
            >
              Get a free consultation
            </a>
          </div>
        </div>
        <div className='col-xs-12 col-sm-4 col-lg-6'>
          <div className='seo-p'>
            Our <h2 className='seo-span'>nearshore development</h2> team is
            ready to start working on your application (including establishing
            requirements and market scope).&nbsp;
            <h2 className='seo-span'>Offshore outsourcing</h2> helps you develop
            a project without hiring new staff members and you pay only for what
            you actually get. By&nbsp;
            <h3 className='seo-span'>outsourcing to Poland</h3> you can save up
            to 30% on costs and make a dedicated Zaven team an integral part of
            your company.
          </div>
          <p className='text-sm text-muted'>
            Zaven’s&nbsp;
            <strong className='seo-regular'>
              nearshore/offshore outsourcing
            </strong>&nbsp;
            specialists include: Junior developers, Senior developers, QA
            testers, Project managers, Business managers, Scrum masters and
            Dedicated account managers
          </p>
        </div>
      </div>
    </div>
  </section>
)

export default ServiceOutsourcingOfferCta
