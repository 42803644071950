import React from 'react'

const ServiceOutsourcingDevelopmentModels = () => (
  <section className='service-outsourcing py-5'>
    <div className='container'>
      <div className='row'>
        <div className='col-md-8 offset-md-2'>
          <h4 className='seo-h2 text-sm-center'>Development models</h4>
          <div className='lead my-2 text-sm-center'>
            There are various&nbsp;
            <h3 className='seo-span'>mobile and web outsourcing development</h3>&nbsp;
            models to choose from. We are open-minded and we will adjust to fit
            your and your product’s needs. The only thing that we will never
            change is our high standards.
          </div>
        </div>
      </div>

      <div className='row my-5'>
        <div className='col-sm-12 col-md-4'>
          <h2 className='seo-h3 mt-2'>
            <span className='text-muted display-4 float-left mr-3'>NDC</span>
            Nearshore <br />
            <span className='text-success'>
              development <br />
              center
            </span>
          </h2>
          <br className='clearfix' />
          <div className='seo-p'>
            <strong>A great solution for all European companies</strong>. Our
            team members know English and German.&nbsp;
            <h2 className='seo-span'>Nearshore outsourcing</h2> and a western
            mind-set make it easy for us to establish long-term business
            relationships without boundaries.
          </div>
          <div className='text-sm text-muted'>
            Thanks to great airline connections from and to Poland, we can meet
            with our <strong>nearshore</strong> associates from Europe on a
            regular basis. Now you can focus on your business and let us create
            affordable <h3 className='seo-span'>mobile and web applications</h3>&nbsp;
            for you. Our team is a group of talented and passionate tech experts
            that work according to&nbsp;
            <h3 className='seo-span'>Agile methodologies</h3>. We are more than
            happy to invite you to our office in the tech-center of Poland,
            Wroclaw.
          </div>
        </div>
        <div className='col-sm-12 col-md-4'>
          <h2 className='seo-h3 mt-2'>
            <span className='text-muted display-4 float-left mr-3'>ODC</span>
            Offshore <br />
            <span className='text-success'>
              development <br />
              center
            </span>
          </h2>
          <br className='clearfix' />
          <div className='seo-p'>
            <strong>
              Designed for business partners form United States, Canada,
              Scandinavia, Asia and Australia
            </strong>&nbsp;
            that value properly trained and well-educated experts,
            English-speaking management, European legal and safety standards, as
            well as high quality code.
          </div>

          <div className='text-sm text-muted'>
            ODC makes it possible for us to work with clients from all parts of
            the world.&nbsp;
            <h3 className='seo-span'>Offshore development in Poland</h3> is
            really beneficial for foreign partners: Poland is a member of the
            European Union and the Schengen Agreement, so the currency rate is
            very stable and project expenses are easy to estimate. Global
            standards, effectiveness and speed make us an ideal long-term
            business partner.
          </div>
        </div>
        <div className='col-sm-12 col-md-4'>
          <h2 className='seo-h3 mt-2'>
            <span className='text-muted display-4 float-left mr-3'>DDC</span>
            Dedicated <br />
            <span className='text-success'>
              development <br />
              center
            </span>
          </h2>
          <br className='clearfix' />
          <div className='seo-p'>
            <strong>Ideal for larger IT agencies</strong>, that need to
            outsource a specific part of work without hiring new employees.
          </div>
          <div className='text-sm text-muted'>
            This model allows you to hire a&nbsp;
            <strong>dedicated team of experts</strong>, full-time. Our
            specialists market-savvy and, because they will be working only on
            you project, they will have a wide understanding of your product as
            well, knowing exactly how to bring it to the next level. All you
            will have to do is assign one of your employees that will hand over
            guidelines and receive reports. If you are interested, please send
            us your project brief and we will give a tailored quote for hiring a&nbsp;
            <h3 className='seo-span'>dedicated development team</h3>.
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default ServiceOutsourcingDevelopmentModels
