import React from 'react'
import {Category, scrollToForm} from "../../contact/ContactForm"

const ServiceOutsourcingHireUsCta = () => (
  <section className='partial-cta-mvp'>
    <div className='container'>
      <p className='lead text-center'>
        <strong>Outsourcing with a western mind-set:</strong>&nbsp;
        <span className='text-success'>we're your best match!</span>&nbsp;
        <br className='d-md-none' />
        <a
          href='#contact-partial'
          data-form-category='outsourcing'
          className='intercom-show btn btn-outline-success d-block d-md-inline-block m-3'
          data-toggle='intercom'
          onClick={event => {
            event.preventDefault()
            scrollToForm(Category.Outsourcing, 0, 500)
          }}
        >
          Hire us now!
        </a>
      </p>
    </div>
  </section>
)

export default ServiceOutsourcingHireUsCta
