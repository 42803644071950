import React from 'react'

const ServiceOutsourcingZavenDreamTeam = () => (
  <section className="partial-cta-mvp bg-dark">
    <div className="container">
      <p className="lead text-center">
        <strong>Get your dream team from Zaven:</strong> <span className="text-success">fast, efficient and goal-oriented.</span>
      </p>
    </div>
  </section>
)

export default ServiceOutsourcingZavenDreamTeam