import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faClock, faEye, faGem, faUmbrella} from '@fortawesome/pro-light-svg-icons'

const ServiceOutsourcingOffer = () => (
  <section className='service-outsourcing py-5 bg-faded'>
    <div className='container'>
      <div className='row mb-5'>
        <div className='col-sm-12 col-md-4'>
          <h4 className='seo-h2 text-sm-center text-md-right mt-0'>
            Time & materials
            <br />
            pricing model
          </h4>
        </div>
        <div className='col-sm-12 col-md-8'>
          <div className='card-group mb-5'>
            <div className='card'>
              <div className='card-body'>
                <FontAwesomeIcon icon={faEye} size='2x' className='text-success mb-2' />
                <br />
                <strong>Transparency</strong>
                <br />
                <p className='text-sm text-muted text-italic'>
                  Monthly payments are crucial to keep constant financial
                  control over the project. No hidden costs, no surprises.
                </p>
              </div>
            </div>
            <div className='card'>
              <div className='card-body'>
                <FontAwesomeIcon icon={faClock} size='2x' className='text-success mb-2' />
                <br />
                <strong>Availability</strong>
                <br />
                <p className='text-sm text-muted text-italic'>
                  Dedicated developers work only on your project
                </p>
              </div>
            </div>
            <div className='card'>
              <div className='card-body'>
                <FontAwesomeIcon icon={faUmbrella} size='2x' className='text-success mb-2' />
                <br />
                <strong>Security</strong>
                <br />
                <p className='text-sm text-muted text-italic'>
                  Your code is always safe thanks to security agreements and
                  work policies strictly in line with safety procedures.
                </p>
              </div>
            </div>
            <div className='card'>
              <div className='card-body'>
                <FontAwesomeIcon icon={faGem} size='2x' className='text-success mb-2' />
                <br />
                <strong>Quality</strong>
                <br />
                <p className='text-sm text-muted text-italic'>
                  Our offshore/nearshore development team consists of business
                  goal-oriented senior level developers.
                </p>
              </div>
            </div>
          </div>
          <p>
            <strong>Time and Materials</strong> is a payment model that is
            beneficial for both parties. The transparency of this model ensures
            that the client has more control over costs and will only pay for
            the actual work being done. This budgeting method allows us to keep
            a streamlined workflow and enables the client to pay in convenient
            instalments.
          </p>
          <p>
            The main advantages of <strong>Time & Materials</strong> are:
          </p>
          <ul>
            <li>Constant quality control</li>
            <li>Lower risk factors</li>
            <li>No additional costs</li>
            <li>Project Manager support for a streamlined workflow</li>
          </ul>
          <p>
            This is a great solution for startups, which often lack detailed
            product specifications,
            <strong className='seo-span'> application prototype</strong> scopes
            and time requirements.
          </p>
        </div>
      </div>
    </div>
  </section>
)

export default ServiceOutsourcingOffer
